// Import our custom CSS
import '../scss/main.scss'

// Import all of Bootstrap's JS
import * as bootstrap from 'bootstrap';
window.bootstrap = bootstrap;
// Import our custom CSS
import SmoothScroll from 'smooth-scroll';
import objectFitImages from 'object-fit-images';
import {
    auth,
    signOut,
  } from "./firebase.js";
"use strict";

$(function () {


    // Check if the uid cookie is not set
// if (!document.cookie.includes('uid')) {
//     // Log out the user from Firebase
//     signOut(auth)
//       .then(() => {
//         // Sign-out successful.
//         destroySession();
//         setTimeout(function () {
//           // CREATE PHP SESSION
//           setTimeout(function () {
//             $("#loading-spinner").hide();
//             location = "/";
//           }, 1000);
//         }, 1000);
//       })
//       .catch((error) => {
//         //console.log("logout error");
//       });
// }

    // =====================================================
    //     Reset input
    // =====================================================

    $(".input-expand .form-control").on("focus", function () {
        $(this).parents(".input-expand").addClass("focus");
    });
    $(".input-expand .form-control").on("blur", function () {
        setTimeout(function () {
            $(".input-expand .form-control").parents(".input-expand").removeClass("focus");
        }, 333);
    });

    

    // ------------------------------------------------------- //
    //   Increase/Decrease product amount
    // ------------------------------------------------------ //
    $(".btn-items-decrease").on("click", function () {
        var input = $(this).siblings(".input-items");
        if (parseInt(input.val(), 10) >= 1) {
            if (input.hasClass("input-items-greaterthan")) {
                input.val(parseInt(input.val(), 10) - 1 + "+");
            } else {
                input.val(parseInt(input.val(), 10) - 1);
            }
        }
    });

    $(".btn-items-increase").on("click", function () {
        var input = $(this).siblings(".input-items");
        if (input.hasClass("input-items-greaterthan")) {
            input.val(parseInt(input.val(), 10) + 1 + "+");
        } else {
            input.val(parseInt(input.val(), 10) + 1);
        }
    });

    // ------------------------------------------------------- //
    //   Scroll to top button
    // ------------------------------------------------------ //

    $(window).on("scroll", function () {
        if ($(window).scrollTop() >= 1500) {
            $("#scrollTop").fadeIn();
        } else {
            $("#scrollTop").fadeOut();
        }
    });

    $("#scrollTop").on("click", function () {
        $("html, body").animate(
            {
                scrollTop: 0,
            },
            1000
        );
    });

    // ------------------------------------------------------- //
    // Adding fade effect to dropdowns
    // ------------------------------------------------------ //

    $.fn.slideDropdownUp = function () {
        $(this).fadeIn().css("transform", "translateY(0)");
        return this;
    };
    $.fn.slideDropdownDown = function (movementAnimation) {
        if (movementAnimation) {
            $(this).fadeOut().css("transform", "translateY(30px)");
        } else {
            $(this).hide().css("transform", "translateY(30px)");
        }
        return this;
    };

    $(".navbar .dropdown").on("show.bs.dropdown", function (e) {
        $(this).find(".dropdown-menu").first().slideDropdownUp();
    });
    $(".navbar .dropdown").on("hide.bs.dropdown", function (e) {
        var movementAnimation = true;

        // if on mobile or navigation to another page
        if ($(window).width() < 992 || (e.clickEvent && e.clickEvent.target.tagName.toLowerCase() === "a")) {
            movementAnimation = false;
        }

        $(this).find(".dropdown-menu").first().slideDropdownDown(movementAnimation);
    });

    // ------------------------------------------------------- //
    //    Collapse button control (used for more/less filters)
    // ------------------------------------------------------ //

    $(".btn-collapse").each(function () {
        var button = $(this),
            collapseId = button.attr("data-bs-target");

        if ($(collapseId).length) {
            var collapseElement = $(collapseId);

            $(collapseElement).on("hide.bs.collapse", function () {
                button.text(button.attr("data-collapsed-text"));
            });

            $(collapseElement).on("show.bs.collapse", function () {
                button.text(button.attr("data-expanded-text"));
            });
        }
    });

    // ------------------------------------------------------- //
    //   Bootstrap tooltips
    // ------------------------------------------------------- //

    // $('[data-bs-toggle="tooltip"]').tooltip();

    // ------------------------------------------------------- //
    //   Smooth Scroll
    // ------------------------------------------------------- //

    var smoothScroll = new SmoothScroll("a[data-smooth-scroll]", {
        offset: 80,
    });

    // ------------------------------------------------------- //
    //   Object Fit Images
    // ------------------------------------------------------- //

    objectFitImages();
});


